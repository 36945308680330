/**
 * オブジェクトの階層をフラットにします。
 */
export const FlatObj = (obj: any, prefix = ''): { [key: string]: any } => {
  const flattedObj: any = {};
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      const preKey = prefix + key + '.';
      Object.assign(flattedObj, FlatObj(value, preKey));
    } else {
      const newKey = prefix + key;
      flattedObj[newKey] = value;
    }
  });
  return flattedObj;
};

/**
 * 一定時間処理を待機
 */
export const Sleep = (span: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, span);
  });
};
