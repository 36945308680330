import { default as about_45usGQBdG86skKMeta } from "/vercel/path0/pages/[lang]/about-us.vue?macro=true";
import { default as aboutBrns3ccTCBMeta } from "/vercel/path0/pages/[lang]/about.vue?macro=true";
import { default as contact_45uswdN6M79snsMeta } from "/vercel/path0/pages/[lang]/contact-us.vue?macro=true";
import { default as contactjrM9SjWgGYMeta } from "/vercel/path0/pages/[lang]/contact.vue?macro=true";
import { default as indexJuAInQCqHcMeta } from "/vercel/path0/pages/[lang]/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
export default [
  {
    name: about_45usGQBdG86skKMeta?.name ?? "lang-about-us",
    path: about_45usGQBdG86skKMeta?.path ?? "/:lang()/about-us",
    meta: about_45usGQBdG86skKMeta || {},
    alias: about_45usGQBdG86skKMeta?.alias || [],
    redirect: about_45usGQBdG86skKMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[lang]/about-us.vue").then(m => m.default || m)
  },
  {
    name: aboutBrns3ccTCBMeta?.name ?? "lang-about",
    path: aboutBrns3ccTCBMeta?.path ?? "/:lang()/about",
    meta: aboutBrns3ccTCBMeta || {},
    alias: aboutBrns3ccTCBMeta?.alias || [],
    redirect: aboutBrns3ccTCBMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[lang]/about.vue").then(m => m.default || m)
  },
  {
    name: contact_45uswdN6M79snsMeta?.name ?? "lang-contact-us",
    path: contact_45uswdN6M79snsMeta?.path ?? "/:lang()/contact-us",
    meta: contact_45uswdN6M79snsMeta || {},
    alias: contact_45uswdN6M79snsMeta?.alias || [],
    redirect: contact_45uswdN6M79snsMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[lang]/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contactjrM9SjWgGYMeta?.name ?? "lang-contact",
    path: contactjrM9SjWgGYMeta?.path ?? "/:lang()/contact",
    meta: contactjrM9SjWgGYMeta || {},
    alias: contactjrM9SjWgGYMeta?.alias || [],
    redirect: contactjrM9SjWgGYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[lang]/contact.vue").then(m => m.default || m)
  },
  {
    name: indexJuAInQCqHcMeta?.name ?? "lang",
    path: indexJuAInQCqHcMeta?.path ?? "/:lang()",
    meta: indexJuAInQCqHcMeta || {},
    alias: indexJuAInQCqHcMeta?.alias || [],
    redirect: indexJuAInQCqHcMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[lang]/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  }
]