<script setup lang="ts">
// [dayjs]
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import ja from 'dayjs/locale/ja';
// [stores]
import { useAppStore } from '@/stores/app-store';
import { useLangStore } from '@/stores/lang-store';
// [lib]
import { Sleep } from '@/lib/lib-com';
// ---------------------------------------------------
// [dayjs]
/* eslint-disable */
dayjs.extend(advancedFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale(ja);
/* eslint-enable */
// ---------------------------------------------------
// [ Nuxt ]
const route = useRoute();
const config = useRuntimeConfig();
// [ stores ]
const appStore = useAppStore();
// [ store - lang ]
const langStore = useLangStore();
langStore.init(/^\/en/.test(route.path) ? 'en' : 'ja');
const t = langStore.t;
// ---------------------------------------------------
useHead({
  htmlAttrs: { lang: () => langStore.state.lang, prefix: 'og: http://ogp.me/ns#' },
  meta: [
    { 'http-equiv': 'refresh', content: '' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1.0,maximum-scale=1.0' },
    { name: 'format-detection', content: 'telephone=no' },
    { name: 'theme-color', content: '#1C1C33' },
  ],
  link: [
    { rel: 'shortcut icon', href: '/assets/icon/favicon.ico', type: 'image/x-icon' },
    { rel: 'apple-touch-icon', href: '/assets/icon/apple-touch-icon.png' },
    { rel: 'apple-touch-icon', href: '/assets/icon/apple-touch-icon-57x57.png', sizes: '57x57' },
    { rel: 'apple-touch-icon', href: '/assets/icon/apple-touch-icon-72x72.png', sizes: '72x72' },
    { rel: 'apple-touch-icon', href: '/assets/icon/apple-touch-icon-76x76.png', sizes: '76x76' },
    { rel: 'apple-touch-icon', href: '/assets/icon/apple-touch-icon-114x114.png', sizes: '114x114' },
    { rel: 'apple-touch-icon', href: '/assets/icon/apple-touch-icon-120x120.png', sizes: '120x120' },
    { rel: 'apple-touch-icon', href: '/assets/icon/apple-touch-icon-144x144.png', sizes: '144x144' },
    { rel: 'apple-touch-icon', href: '/assets/icon/apple-touch-icon-152x152.png', sizes: '152x152' },
    { rel: 'apple-touch-icon', href: '/assets/icon/apple-touch-icon-180x180.png', sizes: '180x180' },
  ],
  script: [
    //
  ],
  noscript: [{ children: 'JavaScript is required' }],
  bodyAttrs: {
    class: () => (/^\/en/.test(route.path) ? 'lang-en' : 'lang-ja'),
  },
});

// ---------------------------------------------------
const isHide = ref(false);
watch(
  () => appStore.state.isReady,
  async () => {
    if (appStore.state.isReady) {
      await Sleep(1000);
      isHide.value = true;
    }
  }
);
const fixed = computed(() => ({ height: appStore.windowFixedH }));
onMounted(() => {
  console.log(`${config.public.p.name} [${config.public.p.version}]:${config.public.env}  It is running on Vercel.`);
  appStore.init();
});
</script>
<template>
  <NuxtLayout>
    <Teleport to="body">
      <div v-if="!isHide" class="base-cover" :class="{ isReady: appStore.state.isReady }" :style="fixed">
        <span class="loader"></span>
      </div>
      <div class="lang-toggle">
        <v-btn variant="text" rounded="0" color="white" @click="langStore.toggleLang()">
          {{ t('com.langtoggle') }}
        </v-btn>
      </div>
    </Teleport>
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang="scss" scoped>
.base-cover {
  position: fixed;
  inset: 0;
  background-color: rgb(0, 0, 0);
  z-index: 9999;
  transition: opacity 400ms;
  will-change: opacity;
  pointer-events: all;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  &.isReady {
    opacity: 0;
    pointer-events: none;
  }
}

.loader {
  max-width: 300px;
  width: calc(100% - 20px);
  height: 5px;
  display: inline-block;
  position: relative;
  background: rgba(66, 57, 130, 0.598);
  overflow: hidden;
  &::after {
    content: '';
    width: 40%;
    height: 20px;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    animation: base-loader 800ms linear infinite;
  }
}
.lang-toggle {
  position: fixed;

  mix-blend-mode: difference;

  @media screen and (min-width: #{ 0 }px) and (max-width: #{ 600 - 0.1}px) {
    inset: 6px 6px auto auto;
  }

  @media screen and (min-width: #{ 600 }px) {
    inset: 10px 10px auto auto;
  }

  z-index: 1;
  > button {
    width: 100px;
    background-color: rgba(250, 250, 250, 0.247);
  }
}
</style>
